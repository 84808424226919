'use strict';
/**
 * File includes the ajax call to submit the notifyMe form
 */
let formValidation = require('../components/formValidation');

/**
 * Add error message to invalid feedback container
 * @param  {string} message validation error message
 * @return {void}
 */
function showValidationFeedback(message) {
    $('.invalid-feedback').text(message).show();
}

/**
 * Clears and hides the invalid feedback container
 * @return {void}
 */
function clearValidationFeedback() {
    $('.is-invalid').removeClass('is-invalid');
    $('.invalid-feedback').empty().hide();
}

/**
 * Validates HTML input fields
 * @param  {HTMLElement} inputElem HTML input element to be validated
 * @return {array} is input valid, validation message
 */
function checkValidInput(inputElem) {
    inputElem.setCustomValidity('');
    if (!inputElem.checkValidity()) { // if input is invalid
        $(inputElem).addClass('is-invalid').closest('.form-group').addClass('is-invalid');
        return [false, inputElem.validationMessage];
    }
    return [true, ''];
}

/**
 * Validates form by checking each input
 * @return {boolean} is form valid
 */
function validateNotifyMeForm() {
    clearValidationFeedback();
    if ($('.notifyMeForm')[0] && !$('.notifyMeForm')[0].checkValidity()) { // if form invalid
        let errors = '';
        $('.notifyMeForm div.material-text-input').find('input').each(function () { // loop through inputs
            let [validationResult, validationResponse] = checkValidInput(this);
            if (!validationResult) { // if input is invalid
                errors += validationResponse + ' '; // add error message
            }
        });
        if (errors) {
            showValidationFeedback(errors); // display error messages
            return false;
        }
    }
    return true;
}

/**
 * If the form is valid submit it
 * @return {boolean} is form valid
 */
function submitNotifyMeForm() {
    let isValid = validateNotifyMeForm();
    if (isValid) { // if form is valid
        $('.notifyMeForm').trigger('submit');
        $('.notify-me.btn').hide();
    }
    return isValid;
}

/**
 * Handles AJAX success response
 * @param  {Object} data AJAX return data
 * @return {void}
 */
function handleNotifyMeSuccess(data) {
    // hide velocity message and form (prevent duplicate submission)
    $('.product__message--velocity').removeClass('product__message--velocity--notifyme').hide();
    $('.notifyMeForm').hide();
    // show response message
    $('.notifyMeFormMessage').html(data.message).show();
}

/**
 * Changes form submit to make AJAX call
 * @return {void}
 */
function registerNotifyMeForm() {
    $(document).on('submit', '.notifyMeForm', function (e) {
        let form = $(this);
        e.preventDefault();
        let url = form.attr('action');

        if (!this.elements.variantPID.value) {
            throw new Error('Notify Me: product variant not selected, invalid request.');
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                if (!data.success && data.success === false && 'fields' in data) {
                    formValidation(form, data);
                } else {
                    handleNotifyMeSuccess(data);
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

/**
 * Builds the Notify Me form
 * @param {boolean|undefined} hideForm create notify me form without unhiding it
 * @return {void}
 */
function createNotifyMeForm(hideForm) {
    var $formContainer = $('.js-notifyMeFormContainer');
    var $button = $('.notify-me.btn');

    if ($button.data('cta') === 'showForm') {
        /**
         * Instead of remote including the Notify Me form
         * when the page is loaded, only make the call
         * when the form is requested (lazy load)
         */
        if ($button.data('is-loading')) {
            return;
        }

        var lazyLoadUrl = $button.data('lazy-load-url');

        if (!hideForm) {
            // show form
            $formContainer.show();
        }

        if ($formContainer.is(':empty')) {
            /**
             * Mark as loading before the call returns so rapid clicks by the user
             * doesn't load the form multiple times.
             */
            $button.data('is-loading', true);

            $.ajax({
                url: lazyLoadUrl,
                method: 'GET',
                success: function (responseHTML) {
                    $formContainer.append(responseHTML);

                    // form creation event will prep form for validation
                    $formContainer.find('form').trigger('form:created');

                    // add variant ID to variant form input
                    $formContainer.find('#variantPID').val($button.data('pid'));

                    // hide response message
                    $formContainer.find('.notifyMeFormMessage').empty().hide();

                    // register listener for form submission
                    $button.data('cta', 'submitForm');
                    registerNotifyMeForm();
                },
                complete: function () {
                    $button.data('is-loading', false);
                }
            });
        } else {
            // show form in case it is hidden
            $formContainer.find('.notifyMeForm').show();
            $button.data('cta', 'submitForm');
        }
    } else if ($button.data('cta') === 'submitForm') {
        submitNotifyMeForm();
    }
}

module.exports = {
    init: function () {
        $(document).on('click', '.notify-me.btn', function () {
            createNotifyMeForm();
        });

        $(window).on('product:triggerNotifyMe', function (e, options) {
            const hideForm = options && options.hideForm;
            createNotifyMeForm(hideForm);
        });

        $(document).on('change blur', '.notifyMeForm input', function (e) {
            clearValidationFeedback();

            let [validationResult, validationResponse] = checkValidInput(this);

            if (!$(e.target).val()) {
                $(e.target).closest('.focus-fill').removeClass('focus-fill');
            }
            if (!validationResult) {
                showValidationFeedback(validationResponse);
            }
        });

        $(document).on('focus', '.notifyMeForm input', function (e) {
            $(e.target).closest('.form-group').addClass('focus-fill');
        });
    }
};
